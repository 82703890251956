@import '../../modern-ui/_common/colors/colors.css';
.animate-fade-in-end {
  transform: scale(1);
}
@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes swing {
  15% {
    transform: translateX(3px);
    -webkit-transform: translateX(3px);
  }
  30% {
    transform: translateX(-3px);
    -webkit-transform: translateX(-3px);
  }
  50% {
    transform: translateX(2px);
    -webkit-transform: translateX(2px);
  }
  65% {
    transform: translateX(-2px);
    -webkit-transform: translateX(-2px);
  }
  80% {
    transform: translateX(1px);
    -webkit-transform: translateX(1px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
  }
}
.textHeader {
  margin: 0 0 4px 0;
  color: rgba(39, 45, 51, 0.98);
}
.text {
  margin: 0;
  letter-spacing: normal;
}
.accented {
  color: var(--black);
  font-weight: 600;
}
ai-setup-page {
  /*angular component style*/
  background-color: var(--greyLight20);
  min-height: 100%;
  max-width: unset !important;
  padding: 0 18px !important;
}
.layout {
  min-width: 1024px;
  width: 1024px;
  margin: 0 auto;
  padding: 24px;
}
.inlineLayout {
  margin: 0 0 18px;
}
.searchField {
  margin-top: 4px;
  width: 347px;
}
.lineLayout {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 0 24px;
}
.subLineLayout {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.subLineLayoutLeft {
  display: flex;
  justify-content: left;
  align-items: center;
}
.infoText {
  max-width: 540px;
  padding-bottom: 12px;
}
.tabsBoxLayout {
  margin: 0 0 18px;
  position: relative;
}
.intentBoxLayout {
  display: flex;
  flex-direction: column;
}
.intent_layout {
  display: flex;
  justify-content: space-between;
  vertical-align: top;
  width: 100%;
  page-break-inside: avoid;
  break-inside: avoid;
  margin: 0 0 16px;
  position: relative;
  padding-right: 8px !important;
  padding-left: 20px !important;
  padding-top: 20px !important;
}
.controlSpacing {
  margin: 0 0 16px;
}
.inline {
  display: inline-block;
  vertical-align: bottom;
}
.groupNameInputLayout {
  width: 320px;
}
.groupNameSpacing {
  margin: 0 10px 0 0;
}
.inputSpacing {
  padding-top: 4px;
}
.inputBackground {
  background-color: var(--greyLight20);
}
.buttonSpacing {
  margin-right: 4px;
}
.actionItemInputLayout {
  display: block;
  width: 100%;
  border: none;
  border-radius: 4px;
  resize: none;
  padding: 12px 20px !important;
  font-size: 15px;
  line-height: 20px;
}
.actionItemInputLayout:disabled {
  background-color: inherit;
}
.actionSuggest {
  margin: 4px;
}
.actionContainer {
  position: relative;
}
.languageSelectorLayout {
  display: inline;
}
.textCapitalize {
  text-transform: capitalize;
}
.popupControlLayout {
  width: 320px;
  position: relative;
  margin: 0 auto 24px;
}
.groupNameFont {
  color: var(--black);
}
.botsDropdownButtonLabel {
  display: flex;
  align-items: center;
}
.botsDropdownButtonLabelIcon {
  margin: 1px 6px 0 -8px;
}
.cloneDonePopup {
  text-align: center;
}
.cloneDonePopupImg {
  display: block;
  width: 85px;
  height: 64px;
  margin: 44px auto 28px;
}
.loaderLayout {
  max-height: calc(100vh - 64px);
  overflow: hidden;
}
.loadingIntentPlaceholder {
  background-color: var(--grey) !important;
  height: 180px;
  animation: flickerAnimation 1000ms infinite;
}
.loadingControlPlaceholder {
  width: 320px;
  height: 36px;
  border-radius: 4px;
  background-color: var(--grey);
  animation: flickerAnimation 1000ms infinite;
}
.iconWhite {
  fill: var(--white);
}
.iconGray {
  fill: #979797;
}
.iconInText {
  margin-bottom: -1px;
}
.addIcon {
  margin-right: 4px;
}
.linesContainer {
  width: 585px;
  margin-right: 42px;
}
.repliesContainer {
  width: 392px;
}
.repliesContainer > .label,
.linesContainer > .label {
  color: #adadad;
  margin-bottom: 4px;
}
.editorContainer {
  min-height: 114px;
}
.repliesList {
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(16, 16, 16, 0.08), 0 1px 0 0 rgba(16, 16, 16, 0.04);
  margin-right: 36px;
  width: 348px;
}
.repliesList .infoText {
  padding: 8px 20px;
}
.menuDots {
  border: none;
  outline: none;
  position: absolute;
  color: var(--greyDark);
  top: 10px;
  right: 8px;
  width: 28px;
  height: 28px;
  padding: 0;
  background-color: transparent;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), var(--white) 24%, var(--white));
}
.menuDots:hover {
  color: var(--blue);
}
.removeActionButton {
  position: absolute;
  right: 0;
  top: 5px;
}
.searchInputPad {
  position: absolute;
  top: -5px;
  right: 0;
  width: 348px;
}
.removeActionButtonContainer {
  position: absolute;
  top: 0;
  right: -36px;
  bottom: 0;
  width: 36px;
}
.divider {
  height: 1px;
  background-color: var(--grey);
  margin: 0 12px;
}
.intentPlaceholder {
  height: 176px;
  border-radius: 4px;
  border: solid 1px rgba(16, 16, 16, 0.08);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  opacity: 0.5;
  text-align: center;
  margin-bottom: 20px;
}
.intentPlaceholderHeader {
  width: 568px;
  height: 32px;
  font-size: 23px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.04;
  letter-spacing: normal;
  color: var(--greyDark);
}
.intentPlaceholderText {
  height: 60px;
  width: 444px;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--greyDark);
}
.pinnedButtonPad {
  position: fixed;
  bottom: 0;
  height: 68px;
  background-color: rgba(243, 242, 242, 0.96);
  padding-top: 16px;
  margin-bottom: 0;
  width: 100%;
}
.addButtonWrapper {
  width: 100%;
  z-index: 10;
  position: relative;
}
.listBottom {
  height: 52px;
}
.textActionTextarea {
  padding: 12px 20px 0 !important;
}
